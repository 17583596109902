import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Organization, OrganizationTerminal } from "../gateways/cms/organization";
//import { useDownloadOrganizationTerminalQuery } from "../gateways/cms/organizationApi";
//import { OrganizationTerminal } from "../components/OrganizationName";
import axios from 'axios';
import Config from "../Config";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { AsyncZipOptions, zip } from "fflate";

import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

export interface Props {
    organization: Organization;
    onOrganizationDelte: (id: number) => void;
}

const MinimumMenu = styled((props: MenuProps) => (
    <Menu
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 2,
        
        "& .MuiList-root": {
            padding: 0,
            
        },
        "& .MuiDivider-root": {
            marginTop: 0,
            marginBottom: 0,
        },
        "& .MuiList-root .delete": {
            color: "#FC6F6C",
        },
    },
}));
const MenuConfig = styled(MenuItem)`
    font-size: 14px;
`;

export const OrganizationEditMenu = ({
    organization,
    onOrganizationDelte,
}: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const close = () => {
        setAnchorEl(null);
    };
    // 各ボタンの処理
    const handleDetail = () => {
        navigate("/organizations/" + organization.id + "/edit");
        close();
    };
    const handleDelte = () => {
        if (organization.childOrganization) {
            alert(organization.name + "には所属テナントが存在するため削除できません");
            close();
        }
        else if (organization.deliveryGroup && organization.deliveryGroup.length > 0) {
            alert(organization.name + "には配信先が存在するため削除できません");
            close();
        }
        else {
            onOrganizationDelte(organization.id);
            close();
        }
    };

    const token = useSelector((state: RootState) => state.auth.token);
    // 端末情報ファイル名取得
    const getTerminalDataFileName = (jsonData: OrganizationTerminal):string => {
        let fileName = organization.name+"_"+jsonData.terminalName+".json";
        return fileName;
    };
    /*
    const getOrganizationDataFileName = ():string => {
        let dateTime = new Date();
        let year = dateTime.getFullYear();
        let month = ('00' + (dateTime.getMonth()+1)).slice(-2);
        let day = ('00' + dateTime.getDate()).slice(-2);
        let hour = ('00' + dateTime.getHours()).slice(-2);
        let minute = ('00' + dateTime.getMinutes()).slice(-2);
        let second = ('00' + dateTime.getSeconds()).slice(-2);

        let fileName = organization.name+"_"+year+"."+month+"."+day+"-"+hour+"."+minute+"."+second+".zip";
        return fileName;
    };
    */

    /*** 端末情報jsonデータダウンロード ***/
    const handleDownloadOrganizationTerminal = async() => {
        let files:File[] = [];
        // 端末情報ダウンロードAPI実行
        const instance = axios.create({
            baseURL: Config.baseEndpoint,
            headers: {
                Accept: "application/json, application/octet-stream",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        // jsonファイル作成
        const response = await instance.get(`organizations/terminal/${organization.id}`);
        const jsonDatas = response.data as OrganizationTerminal[];
        const fileContents: Record<string, Uint8Array> = {};
        const promises = jsonDatas.map(async (jsonData) => {
            const jsonFileName = getTerminalDataFileName(jsonData);
            const jsonFileData = { uniqueId: jsonData.uniqueId, apiUri: jsonData.apiUri, token: jsonData.token };
            const blobData = new Blob([JSON.stringify(jsonFileData, null, "\t")], { type: 'text/json' });
            const jsonFile = new File([blobData], jsonFileName, { type: blobData.type })
            files.push(jsonFile);
            const buffData = await blobData.arrayBuffer();
            fileContents[getTerminalDataFileName(jsonData)] = new Uint8Array(buffData);
        });
        // zipファイル作成
        //const fileName = getOrganizationDataFileName();
        const fileName = organization.name+".zip";
        await Promise.all(promises);
        const zipFile = await compress(files, fileName, {level: 1});
        // ファイルダウンロード
        const link = document.createElement('a');
        const fileUrl = window.URL.createObjectURL(zipFile);
        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(fileUrl);

        /*
        let h = 100;
        let w = 400;
        let wTop = window.screenTop + (window.innerHeight / 2) - (h / 2);
        let wLeft = window.screenLeft + (window.innerWidth / 2) - (w / 2);
        let newWindow = window.open("", "_blank", 'width=' + w + ', height=' + h + ', top=' + wTop + ', left=' + wLeft + ', menubar=0, personalbar=0, toolbar=0, scrollbars=0, resizable=!');
        if (newWindow) {
            newWindow.document.write(fileName + " をダウンロードしました");
            setTimeout(function () {
                if (newWindow) newWindow.close();
            }, 2000);
        }
        */
        alert(fileName + " をダウンロードしました");
        return;
    }

    /*** jsonファイルのzip化 ***/
    const compress = async (
        files: File[],
        filename: string,
        compressOptions: AsyncZipOptions | undefined = undefined
    ): Promise<File> => {
        try {
            const options = compressOptions || {};
            const fileContents: Record<string, Uint8Array> = {};
            const promises = files.map(async (f) => {
                const arrayBuffer = await f.arrayBuffer();
                fileContents[f.name] = new Uint8Array(arrayBuffer);
            });
            await Promise.all(promises);
            const zippedContent: Uint8Array = await new Promise((resolve, reject) => {
                zip(fileContents, options, (err, data) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(data);
                });
            });
            return new File([zippedContent], filename);
        } catch (err) {
            return Promise.reject(new Error(`compress failed: ${err}`));
        }
    };

    return (
        <div>
            <Button
                id="schedul-article-menu-button"
                aria-controls={open ? "schedul-article--menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            ></Button>
            <MinimumMenu
                id="schedul-article--menu"
                MenuListProps={{
                    "aria-labelledby": "schedul-article-menu-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={close}
            >
                <MenuItem onClick={handleDetail} href={`/organization/${organization.id}/edit`}>詳細情報</MenuItem>
                <Divider sx={{ my: 10.5 }} />
                {organization.deliveryGroup && organization.deliveryGroup.length > 0 ? (
                <>
                    <MenuConfig onClick={handleDownloadOrganizationTerminal} >コンフィグダウンロード</MenuConfig>
                    <Divider sx={{ my: 0.5 }} />
                </>
                ):(<></>)}
                <MenuItem onClick={handleDelte} className="delete">削除</MenuItem>
            </MinimumMenu>
        </div>
    );
};
