import produce from "immer";
import { FormEvent, useState, ReactNode } from "react";
import { User, UserForm as UserData, UserOrganizationForm } from "../gateways/cms/user";
import { DeliveryGroup } from "../gateways/cms/deliveryGroup";
import { SelectOrganization } from "./SelectOrganization";
import { SelectUserDeliveryGroup } from "./SelectOrganizationDeliveryGroup";
import { SelectUserGroup } from "./SelectUserGroup";

import { styled } from "@mui/system";
import ButtonMUI from "@mui/material/Button";
import VarConst from "../styles/variables.js";
import iconDelete from "../assets/icons/icon_delete.svg";
import { OrganizationAddForm } from "./OrganizationAddForm";

export interface Props {
    user: User;
    onSubmit: (data: UserData) => void;
    onDismiss: () => void;
    passResetButton: ReactNode;
    deleteButton: ReactNode;
}

// style
const FormModified = styled("form")`
    padding-bottom: 64px;

    label {
        display: block;
        margin-bottom: 4px;
    }
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    select {
        height: 32px;
        padding: 0 0.5em;
        width: 100%;
    }
`;
const SectionTitle = styled("h2")`
    margin: 0 0 24px;
    padding: 10px 10px 10px 40px;
    height: 40px;
    font-weight: normal;
    font-size: 20px;
    background-color: #f4f4f4;
    line-height: 1;
`;
const SectionInfo = styled("h2")`
    margin: 0 0 24px;
    padding: 10px 10px 10px 60px;
    height: 40px;
    font-weight: normal;
    font-size: 16px;
    line-height: 1;
`;
const InputBasic = styled("ul")`
    display: grid;
    grid-template-columns: 12.5% 12.5% 25% 50%;
    padding: 0 24px 24px;

    & > li {
        margin-bottom: 24px;
        &:nth-of-type(1) {
            grid-row: 1/2;
            grid-column: 1/2;
            padding-right: 12px;
        }
        &:nth-of-type(2) {
            grid-row: 1/2;
            grid-column: 2/3;
            padding-left: 12px;
            padding-right: 12px;
        }
        &:nth-of-type(3) {
            grid-row: 1/2;
            grid-column: 3/4;
            padding-left: 12px;
        }
        &:nth-of-type(4) {
            grid-row: 2/3;
            grid-column: 1/3;
            padding-right: 12px;
        }
        &:nth-of-type(5) {
            grid-row: 2/3;
            grid-column: 3/4;
            padding-left: 12px;
        }
        &:nth-of-type(6) {
            grid-row: 3/3;
            grid-column: 1/1;
            width: 100%;
            white-space: nowrap;
            & > input{
                text-align: right
            }
        }
        &:nth-of-type(7) {
            position: relative;
            grid-row: 3/3;
            grid-column: 2/2;
            padding-left: 5px;
            top: 28px;
            width: 20%;
            font-size: 18px;
            color: gray;
        }
    }
`;
const InputOrganization = styled("ul")`
    padding: 0 24px;

    & + div {
        padding: 0 24px 40px;
        display: flex;
        justify-content: center;

        select {
            width: 30em;
            /* margin-right: 24px; */
        }
        .MuiButton-contained {
            width: auto;
            min-width: 116px;
        }
    }

    & > li {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: vertical;
        margin-bottom: 24px;
        padding: 24px;
        border: 1px solid #daddde;

        & > div {
            &:nth-of-type(1) {
                width: 25%;
                select[disabled] {
                    appearance: none;
                    background-color: #f4f4f4;
                    border: none;
                    pointer-events: none;
                }
            }
            &:nth-of-type(2) {
                width: 12.5%;
                margin-left: 24px;
            }
            &:nth-of-type(3) {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                padding: 0 24px;
                padding-top: 24px;
                label {
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    margin: 0;
                    padding: 12px 16px;
                }
            }
        }
        section {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding-top: 24px;

            & > div {
                width: 35em;
                flex-basis: auto;

                &:nth-of-type(1) {
                    display: none;
                }

                label {
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    margin: 0;
                    padding: 12px 16px;
                }
            }
        }

        input[type="text"] {
            margin-right: 16px;
        }
        button + button {
            margin-left: 16px;
        }
    }
`;
const InputPassReset = styled("div")`
    padding: 0 24px 24px;
`;
const UserEditFooter = styled("footer")`
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    width: calc(100vw - 260px);
    padding: 16px 24px;
    background-color: #f4f4f4;
`;
const Button = styled(ButtonMUI)`
    &,
    &.MuiButton-outlined,
    &.MuiButton-contained {
        width: 116px;
        height: 32px;
        border-radius: 2px;
    }
    &.MuiButton-outlined {
        background-color: #fff;
        border: 2px solid #c9cbd3;
    }
    &.MuiButton-contained {
        margin-left: 16px;
        color: #f4f4f4;
        background-color: #3a3e5d;
        border-color: #3a3e5d;
    }
`;
const ButtonOrganizationDelete = styled("button")`
    position: absolute;
    right: 16px;
    top: 16px;
    height: 32px;
    width: 32px;
    min-width: 32px;
    border: ${VarConst.border1};
    border-width: 2px;
    border-radius: 2px;
    background-image: url(${iconDelete});
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #fff;
    background-size: 80%;
    overflow: hidden;
    color: transparent;
    user-select: none;
    cursor: pointer;
    border-color: transparent;
`;

export function UserForm({ user, onSubmit, onDismiss, passResetButton, deleteButton }: Props) {
    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email);
    const [limit, setLimit] = useState(0);
    const [organizations, setOrganizations] = useState<UserOrganizationForm[]>(user.organization);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit({
            id: user.id,
            loginId: user.login_id,
            name,
            email,
            limit,
            organization: organizations,
        });
        onDismiss();
    };

    const changeOrganizationUserGroup = (index: number, userGroupId: string) => {
        setOrganizations(
            produce(organizations, (draft) => {
                draft[index].usergroup = { id: Number(userGroupId) };
            })
        );
    };

    const changeOrganizationDeliveryGroups = (index: number, deliveryGroups: DeliveryGroup[]) => {
        setOrganizations(
            produce(organizations, (draft) => {
                draft[index].deliveryGroup = deliveryGroups.map((deliveryGroup) => ({ id: Number(deliveryGroup.id) , invalid: Boolean(deliveryGroup.invalid) }));
            })
        );
    };

    const addOrganization = (organizationId: number) => {
        setOrganizations([...organizations, { id: organizationId }]);
    };

    const removeOrganization = (organizationId: number) => {
        setOrganizations(organizations.filter((o) => o.id !== organizationId));
    };

    const handleRemoveOrganization = (organizationId: number) => () => removeOrganization(organizationId);

    return (
        <>
            <FormModified onSubmit={handleSubmit}>
                <section>
                    <SectionTitle>基本情報</SectionTitle>
                    <InputBasic>
                        <li>
                            <label htmlFor="id">管理ID</label>
                            <input type="text" id="id" value={user.id} disabled />
                        </li>
                        <li>
                            <label htmlFor="status">状態</label>
                            <input type="text" id="status" value={user.status} disabled />
                        </li>
                        <li>
                            <label htmlFor="login_id">ログインID</label>
                            <input type="text" id="login_id" value={user.login_id} disabled />
                        </li>
                        <li>
                            <label htmlFor="name">名前</label>
                            <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                        </li>
                        <li>
                            <label htmlFor="email">メールアドレス</label>
                            <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </li>
                        <li>
                            <label htmlFor="disk">ディスク容量上限（0.1GB単位で指定可能）</label>
                            <input type="tel" id="disk" placeholder={user.disk.limitGB} onChange={(e) => setLimit(Number(e.target.value))} />
                        </li>
                        <li>
                            <label htmlFor="disk">GB</label>
                        </li>
                    </InputBasic>
                </section>

                <section>
                    <SectionTitle>テナント・アクセス可能配信グループ</SectionTitle>
                    {!user?.checkAdmin ? (
                        <>
                            <InputOrganization>
                                {organizations.map((organization, i) => (
                                    <li key={organization.id}>
                                        <div>
                                            <label htmlFor="organization">テナント</label>
                                            <SelectOrganization value={organization.id} />
                                        </div>
                                        <div>
                                            <label htmlFor="userGroup">権限</label>
                                            <SelectUserGroup
                                                value={String(organization.usergroup?.id || "")}
                                                required
                                                onChange={(id) => changeOrganizationUserGroup(i, id)}
                                            />
                                        </div>
                                        <section>
                                            {/* <label htmlFor="userGroup">配信先権限</label> */}
                                            <SelectUserDeliveryGroup
                                                organizationId={String(organization.id)}
                                                userId={String(user.id)}
                                                //value={organization.deliveryGroup?.map((g) => String(g.id)) || []}
                                                onChange={(deliveryGroups) => changeOrganizationDeliveryGroups(i, deliveryGroups)}
                                            />
                                            {/* TODO: アクセス可能人数表示 */}
                                        </section>
                                        <ButtonOrganizationDelete onClick={handleRemoveOrganization(organization.id)}>
                                            グループ個別削除ボタン
                                        </ButtonOrganizationDelete>
                                    </li>
                                ))}
                            </InputOrganization>
                            <OrganizationAddForm exclude={organizations.map((o) => o.id)} onSubmit={addOrganization} />
                        </>
                    ) : (<SectionInfo>※ システム管理者（全テナントにアクセス可能）※</SectionInfo>)}
                </section>

                <section>
                    <SectionTitle>パスワード</SectionTitle>
                    <InputPassReset>{passResetButton}</InputPassReset>
                </section>

                <UserEditFooter>
                    <div className="footerLeft">{deleteButton}</div>
                    <div className="footerRight">
                        <Button type="button" variant="outlined" onClick={onDismiss}>
                            キャンセル
                        </Button>
                        <Button type="submit" variant="contained" disableElevation>
                            保存
                        </Button>
                    </div>
                </UserEditFooter>
            </FormModified>
        </>
    );
}
