// import { useState } from 'react'
// import { Link } from "react-router-dom";
import { Article } from "../gateways/cms/article";
//import { ScheduleArticleMenu } from "../components/ScheduleArticleMenu";
import { PeriodBarMonthly } from "../components/PeriodBarMonthly";
import { Container, Draggable, DropResult } from "react-smooth-dnd";
// import arrayMove from "array-move";

import { styled } from "@mui/system";
import Chip from "@mui/material/Chip";
//import Tooltip from '@mui/material/Tooltip';
import VarConst from "../styles/variables.js";
import iconEllipsis from "../assets/icons/icon_ellipsis.svg";
import iconEllipsisInvalid from "../assets/icons/icon_ellipsis_invalid.svg";
import { articleStatusNames } from "../gateways/cms/article";
import produce from "immer";
import { selectOrderedArticles, setOrderedArticles } from "../features/cms/orderedArticlesSlice";
import { useDispatch, useSelector } from "react-redux";
// import { useLayoutEffect, useState } from "react";
import { useLayoutEffect } from "react";
// import { CountertopsOutlined } from '@mui/icons-material';
import { ImageModal } from "./materials/ImageModal";

// style
const varCss = {
    scHeaderH: "48px",
    scItemH: "135px",
    border1: "#dadce0 1px solid",
    periodHeaderW: "460px",
    chkboxSize: "24px",
};
const ScheduleList = styled("section")`
    display: flex;
    height: 100%;
    padding: 0 24px;
    overflow: hidden;
    background-color: #fff;

    .scheduleWrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: hidden;
        flex: 1;

        &.ScheduleType-monthly1 {
            .scheduleHeader .scheduleHeaderInner .dateTime {
                flex-wrap: wrap;
                display: block;

                b.mm {
                    display: none;
                }
                b.ww {
                    display: block;
                }
            }

            .scheduleHeader .periodCell {
                &.dow0 {
                    color: #f86464;
                }
                &.dow6 {
                    color: #007aff;
                }
            }
            .scheduleBody .scheduleGrid .periodCell {
                &.dow0::after {
                    border-color: #f86464;
                }
                &.dow6::after {
                    border-color: #007aff;
                }
            }
        }
    }

    /* 期間単位の幅を指定 */
    .periodCell {
        /* min-width: 48px; */
        flex: 1;

        &:last-child {
            flex: unset;
            width: 0;
        }
    }

    /* スケジュール 日付ヘッダ */
    .scheduleHeader {
        height: auto;
        width: 100%;

        .scheduleHeaderInner {
            display: flex;
            position: relative;
            background-color: #fff;
            box-sizing: border-box;
            margin-left: auto;
            padding-left: ${varCss.periodHeaderW};

            .periodCell {
                /* height: ${varCss.scHeaderH}; */
                &:last-child .dateTime {
                    position: absolute;
                    right: 0;
                    transform: translate(50%, -100%);
                }
            }
            /* 時刻表示 */
            .dateTime {
                display: flex;
                justify-content: center;
                position: relative;
                top: 100%;
                min-width: 1em;
                max-width: 3em;
                padding-bottom: 4px;
                text-align: center;
                transform: translate(-50%, -100%);

                & + .dateTime {
                    transform: translate(50%, -200%);
                }

                b {
                    font-weight: normal;
                    letter-spacing: 0;

                    &.mm {
                        &::after {
                            content: "/";
                        }
                    }
                    &.ww {
                        display: none;
                    }
                }
            }
        }
    }

    /* スケジュール 本体 */
    .scheduleBody {
        display: flex;
        align-items: flex-start;
        /* overflow-x: auto; */
        overflow-y: auto;
        /* overflow: hidden; */
        width: 100%;
        border-bottom: ${varCss.border1};
        border-left: ${varCss.border1};

        .scheduleBodyInner {
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: 460px 1fr;

            min-width: 100%;
            flex: none;
            vertical-align: top;

            overflow: hidden;
            position: relative;
            margin-bottom: -1px;
            margin-left: -1px;
        }

        /* グリッド */
        .scheduleGrid {
            display: flex;
            padding-left: ${varCss.periodHeaderW};
            border-top: ${varCss.border1};
            grid-column: 1 / 3;
            grid-row: 1 / 2;

            .periodCell {
                &::after {
                    content: "";
                    position: absolute;
                    z-index: 0; // 初期値 3
                    height: 100%;
                    margin-left: -1px;
                    border-left: ${varCss.border1};
                    border-width: 2px;
                    pointer-events: none;
                }
            }
        }

        .todayMarker {
            position: absolute;
            width: 100%;
            height: 100%;
            /* margin-left: ${varCss.periodHeaderW}; */
            grid-column: 2 / 3;
            grid-row: 1 / 2;

            .marker {
                position: absolute;
                left: 0;
                z-index: 2;
                height: 100%;

                &::before {
                    content: "";
                    position: absolute;
                    display: inline-block;
                    border: 0 solid transparent;
                    border-width: 12px 8px 0 8px;
                    border-top-color: #3a3e5d;
                    transform: translate(-50%, 0);
                }
                &::after {
                    content: "";
                    display: inline-block;
                    height: 100%;
                    border-left: 2px solid #3a3e5d;
                    margin-left: -1px;
                }
            }
        }

        .smooth-dnd-container {
            grid-column: 1 / 3;
            grid-row: 1 / 2;
        }
    }

    /*  左端 コンテンツ概要 見出し  */
    .articleSummary {
        position: relative;
        z-index: 1;
        height: ${varCss.scItemH};
        min-height: ${varCss.scItemH};
        width: ${varCss.periodHeaderW};
        overflow: hidden;
        box-sizing: border-box;
        background-color: white;
        border-right: ${varCss.border1};

        &::after {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            bottom: 0;
            left: 0;
            border-bottom: ${varCss.border1};
        }

        ul {
            width: 100%;
            height: 100%;
            padding: 6px 8px 6px 8px;
            display: grid;
            grid-template-rows: 18% 15% 15% 15% 20% 1fr;
            grid-template-columns: 28% 28% 30% 1fr;

            .title {
                grid-row: 2/3;
                grid-column: 2/5;
            }
            .id {
                grid-row: 1/2;
                grid-column: 4/5;
            }
            .type {
                grid-row: 1/2;
                grid-column: 2/3;
            }
            .week {
                grid-row: 4/5;
                grid-column: 2/5;
            }
            .day {
                grid-row: 5/6;
                grid-column: 2/5;
            }
            .period {
                grid-row: 3/4;
                grid-column: 2/5;
            }
            .at_least {
                grid-row: 1/2;
                grid-column: 3/4;
            }
            .at_least_minimum {
                grid-row: 1/2;
                grid-column: 3/4;
            }
            .owner {
                grid-row: 6/7;
                grid-column: 2/5;
            }
            .updater {
                grid-row: 6/7;
                grid-column: 4/5;
            }
            .thumbnail {
                grid-row: 1/7;
                grid-column: 1/2;
            }

            .id,
            .type,
            .period,
            .week,
            .day,
            .owner,
            .at_least {
                font-size: 12px;
                letter-spacing: 0;
            }
            .at_least_minimum {
                font-size: 12px;
                letter-spacing: 0;
            }
            .id {
                letter-spacing: 0;
                text-indent: 0.75em;
            }
            .title {
                * {
                    margin: 0;
                    font-size: inherit;
                    font-weight: normal;
                }
            }
            .period {
                letter-spacing: 0;
            }
            .week {
                letter-spacing: 0;
            }
            .day {
                letter-spacing: 0;
                padding-left:4.7em;
	            text-indent:-4.7em;
                line-height: 1.1em;
            }
            .at_least {
                text-align: right;
                .MuiChip-root {
                    height: unset;
                    background-color: #9290e1;
                }
            }
            .at_least_minimum {
                text-align: center;
                .MuiChip-root {
                    height: unset;
                    background-color: #22b14c;
                }
            }
            .thumbnail {
                text-align: center;

                img {
                    width: auto;
                    height: auto;
                    max-width: 104px;
                    max-height: 104px;
                }
            }
        }
        ul + div {
            position: absolute;
            top: 8px;
            right: 8px;

            & > button {
                height: 24px;
                width: 24px;
                min-width: 24px;
                border: ${VarConst.border1};
                border-width: 2px;
                border-radius: 2px;
                background-image: url(${iconEllipsis});
                background-repeat: no-repeat;
                background-position: center center;
                background-color: #fff;
            }

            /* POP部分 */
            ul {
                border-radius: 2px;
                padding: 0;

                li {
                    border: ${VarConst.border1};
                }
            }
        }
    }

    /* スケジュール横列 範囲 */
    .scheduleGridCell {
        position: relative;
        flex: 1 1 auto;
        height: ${varCss.scItemH};
        min-height: ${varCss.scItemH};
        margin-bottom: -1px; // 隣接するアイテムの線幅を1pxに
        box-sizing: border-box;
        border: ${varCss.border1};

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: transparent;
            mix-blend-mode: darken;
        }

        // export const articleStatusNames = ["下書き", "確認中", "差戻", "掲載待ち", "掲載中", "掲載終了", "一時停止中", "削除"];
        // export const articleStatusNames = [status0, status1, status2, status3, status4, status5, status6, status7];

        &.status0,
        &.draft {
            &::after {
                background-color: #f5f5f5;
            }
            .articleSummary {
                background-color: #f5f5f5;
            }
            .periodBar {
                background-color: #c9d9ee;
                border-color: #c9d9ee;
            }
        }

        .periodRail {
            position: relative;
            top: -100%;
            height: 100%;
            /* width: 100%; */
            margin-left: ${varCss.periodHeaderW};
        }

        /* スケジュール 棒グラフ */
        .periodBar {
            position: absolute;
            top: 50%;
            z-index: 5;
            height: 32px;
            outline: none;
            color: #fff;
            line-height: 32px;
            text-align: center;
            background-color: #72a1e2;
            border-color: #72a1e2;
            border-radius: 2px;
            transform: translate(0, -50%);
            /* 開始位置と長さは動的に与える(仮の値) */
            left: 0;
            width: 20%;

            h4 {
                margin: 0;
                display: none;
            }
        }
    }
    .menuButton{
        height: 24px;
        width: 24px;
        min-width: 24px;
        border: 2px solid #e0e0e0;
        border-radius: 2px;
        background-image: url(${iconEllipsisInvalid});
        background-repeat: no-repeat;
        background-position: center center;
        background-color: #fff;
        background-size: 80%;
    }
`;

export interface Props {
    deliveryGroupId: number;
    fromDate: string; // 2022-02-01
    toDate: string; // 2022-02-28
    onArticleDuplicate: (id: number, modaloption: string, deliveryGroupId: number) => void;
    onArticleSuspend: (article: Article, suspended: boolean, deliveryGroupId: number, startDate: string) => void;
    className: string;
    articles: any;
    checkAgent: boolean;
}

export const MonthlySchedule = ({
    deliveryGroupId,
    fromDate,
    toDate,
    onArticleDuplicate,
    onArticleSuspend,
    className,
    articles,
    checkAgent,
}: Props) => {
    const dispatch = useDispatch();
    const articleDatas: Article[] = articles.data;
    //const [articleDatas, setArticleDatas] = useState<Article[]>(articles.data);
    const orderedArticles = useSelector(selectOrderedArticles);

    // あんま良くない方法だけどとりあえず保存ボタンを外に置くためにこの実装で
    useLayoutEffect(() => {
        if (articleDatas) {
            dispatch(setOrderedArticles(articleDatas));
        //    setDragging(false);
        } else {
            dispatch(setOrderedArticles([]));
        }
    }, [articleDatas, dispatch]);

    //const [dragging, setDragging] = useState(false);
    const handleDrop = ({ removedIndex, addedIndex }: DropResult) => {
        if (removedIndex === null || addedIndex === null) {
            return;
        }
        const dropArticles= produce(orderedArticles, (draft) => {
            const t = draft.splice(removedIndex, 1);
            draft.splice(addedIndex, 0, ...t);
        });
        dispatch(setOrderedArticles(dropArticles));
    //    setDragging(true);
    };

    const getArticleContentType = (type: String):String => {
        if (type === "minimum-schema") {
            return '回数保証コンテンツ';
        }
        else {
            return '通常コンテンツ';
        }
    };

    const getContentTypeClass = (type: String):string => {
        if (type === "minimum-schema") return 'at_least_minimum';
        else return 'at_least';
    };

    // 日付変換
    let displayFromDate = new Date(fromDate + " 00:00:00"); // 時刻無しで変換すると 09:00 になるので 00:00 に設定
    let displayToDate = new Date(toDate + " 00:00:00");
    let displayStartDate = new Date(displayFromDate);
    displayStartDate = new Date(displayStartDate.setDate(1)); // 表示日の月の1日を開始点にする
    let displayTotalDays = (displayToDate.getTime() - displayStartDate.getTime()) / 1000 / 60 / 60 / 24 + 1; // 0日からではないので

    let thisYear = displayStartDate.getFullYear();
    let thisMonth = displayStartDate.getMonth() + 1;
    let thisMonthDays = new Date(thisYear, thisMonth, 0).getDate();

    let GridCells = 0;
    let GridCellUnit = 1;

    if (displayTotalDays === thisMonthDays) {
        // console.log( "単月" );
        GridCells = thisMonthDays;
    } else {
        // console.log( "2-3ヶ月" );
        GridCellUnit = 7;
        GridCells = Math.ceil(displayTotalDays / GridCellUnit);
    }

    let displayDays = GridCells;
    if (GridCells < 28) {
        displayDays = GridCells * 7;
    }
    // ミリ秒表示の時間 / 1000ms / 60s / 60m / 24h * (全幅100%)
    let dayScale = (1 / 1000 / 60 / 60 / 24 / displayDays) * 100;
    let specifiedDate = (displayFromDate.getTime() - displayStartDate.getTime()) * dayScale;
    let markerPosition = { left: "calc(" + specifiedDate + " * 1%)" };
    let displayLastDate = new Date();

    const getPeriodWeek = (periodWeek: number[]):String => {
        let check = false;
        let week = ["日","月","火","水","木","金","土"];
        let period = "曜日指定 : ";

        for(let i = 0; i < 7; i++){
            if(periodWeek[i] === 1){
                if(!check) check = true;
                period += week[i]+" ";
            }
        }
        if(!check) period = "";
        return period;
    };
    
    const getPeriodDay = (periodDay: number[] | null):String => {
        let period = "日付指定 : ";
        if(periodDay){
            for(let i = 0; i < periodDay.length; i++){
                period += periodDay[i]+" ";
            }
        }
        else{
            period = "";
        }
        return period;
    };
    /*
    const unAuthTooltip = (dragging:boolean, checkAgent:boolean):string => {
        if(checkAgent) return "広告代理店ユーザーは編集できません";
        if(dragging) return "掲載順移動中は編集できません";
        return "広告代理店ユーザーまたは掲載順移動中は編集できません"
    } 
    */

    return (
        <ScheduleList>
            <div className={`scheduleWrapper cellUnit${GridCellUnit} ${className}`}>
                {/*
                        1ヶ月 > 全日付を表示
                        2-3 > 週を表示
                    */}
                {/* <!-- ## ヘッダ表示 --> */}
                <div className="scheduleHeader">
                    <div className="scheduleHeaderInner">
                        {(() => {
                            const cells = [];
                            let dateT = ["日", "月", "火", "水", "木", "金", "土"];
                            let currentDate = new Date(displayStartDate);
                            for (let i = 0; i <= GridCells; i++) {
                                cells.push(
                                    <div className={`periodCell cell${i} dow${currentDate.getDay()} `}>
                                        {" "}
                                        <span className="dateTime">
                                            {" "}
                                            <b className="mm">{currentDate.getMonth() + 1} </b>{" "}
                                            <b className="dd">{currentDate.getDate()} </b>{" "}
                                            <b className="ww">{dateT[currentDate.getDay()]} </b>{" "}
                                        </span>{" "}
                                    </div>
                                );
                                displayLastDate = currentDate;
                                currentDate.setDate(currentDate.getDate() + GridCellUnit);
                            }
                            displayLastDate.setDate(displayLastDate.getDate() - GridCellUnit); // 最後に1週多く足されるので調整
                            return <>{cells}</>;
                        })()}
                    </div>
                </div>

                {/* <!-- ## 予定表示 --> */}
                <div className="scheduleBody">
                    <div className="scheduleBodyInner">
                        <div className="scheduleGrid">
                            {(() => {
                                const cells = [];
                                let currentDate = new Date(displayStartDate);
                                for (let i = 0; i <= GridCells; i++) {
                                    currentDate.setDate(displayStartDate.getDate() + i * GridCellUnit);
                                    cells.push(
                                        <div className={`periodCell cell${i} dow${currentDate.getDay()} `}></div>
                                    );
                                }
                                return <>{cells}</>;
                            })()}
                        </div>

                        <div className="todayMarker">
                            <div className="marker" title={fromDate.replace(/-/g, "/")} style={markerPosition}></div>
                        </div>

                        <Container lockAxis="y" onDrop={handleDrop}>
                            {orderedArticles.map((article) => (
                                <Draggable
                                    className={`DeliveryArticleItem scheduleGridCell status${articleStatusNames.indexOf(
                                        article.status.toString()
                                    )}`}
                                    schedule-id={article.id}
                                    key={article.id}
                                >
                                    <div className="articleSummary" style={{ backgroundColor: article.backgroundColor, color: article.labelColor}}>
                                        <ul>
                                            <li className="title">
                                                <h2>{article.title}</h2>
                                            </li>
                                            <li className="id">ID: {article.id}</li>
                                            {/*<li className="type">{article.schemaGroupId}</li> */}
                                            <li className="period">
                                                {article.periodDayStart.replace(/-/g, "/").slice(0, -3)} 〜{" "}
                                                {article.periodDayEnd?.replace(/-/g, "/").slice(0, -3) || "無期限"}
                                            </li>
                                            <li className="week">
                                                {getPeriodWeek(article.periodWeek)}
                                            </li>
                                            <li className="day">
                                                {getPeriodDay(article.periodDay)}
                                            </li>
                                            <li className={getContentTypeClass(article.content.type)}>
                                                {article.content.type && (
                                                    <Chip
                                                        label={`${getArticleContentType(article.content.type)}`}
                                                        color="primary"
                                                        size="small"
                                                    />
                                                )}
                                            </li>
                                            <li className="owner">コンテンツ所有者: {article.createdBy.name}</li>
                                            {/*<li className="updater">掲載更新者: {article.updatedBy?.name}</li>*/}
                                            <li className="thumbnail">
                                                {article.thumbnailType === 'image' ?
                                                    <ImageModal
                                                        imageUrl={`${article.thumbnailData}`}
                                                        imageAlt={`${article.title}`}
                                                    >
                                                        <img
                                                            src={`${article.thumbnail}`}
                                                            alt={`${article.title}`}
                                                        />
                                                    </ImageModal>
                                                    :
                                                    <a href={`${article.thumbnailData}`} target="_blank" rel="noreferrer">
                                                        <img src={`${article.thumbnail}`} alt={`${article.title}`}></img>
                                                    </a>
                                                }
                                            </li>
                                        </ul>
                                        {/* dragging === false
                                            ? <ScheduleArticleMenu
                                                article={article}
                                                deliveryGroupId={deliveryGroupId}
                                                startDate={fromDate}
                                                onArticleDuplicate={onArticleDuplicate}
                                                onArticleSuspend={onArticleSuspend}
                                              />
                                            : <Tooltip title={unAuthTooltip(dragging, checkAgent)} arrow>
                                                <div className="menuButton" />
                                              </Tooltip>
                                        */}
                                        {/*
                                            <Link to={`/articles/${article.id}/edit`}>詳細情報</Link>
                                            <button onClick={() => onArticleDuplicate(article.id)}>コピー</button>
                                            {/* <button onClick={() => onArticleSuspend(article, true)}>掲載を一時停止</button> * /}
                                            <button onClick={() => onArticleSuspend(article, false)}>掲載</button>
                                        */}
                                    </div>

                                    <div className="periodRail">
                                        <PeriodBarMonthly
                                            article={article}
                                            fromDate={fromDate}
                                            dispLastDate={displayLastDate}
                                            toDate={toDate}
                                            gridCells={GridCells}
                                        />
                                        {/*
                                        <div className="periodBar">
                                            <h4 className="ayClmf"> {article.title} </h4>
                                            <div className="status" title={`${article.periodTimeStart} ～ ${article.periodTimeStart}`}> {article.status} </div>
                                        </div>
                                         */}
                                    </div>
                                </Draggable>
                            ))}
                        </Container>
                    </div>
                </div>
            </div>
        </ScheduleList>
    );
};
