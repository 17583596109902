import { ChangeEvent, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useBulkDeleteSchemaDefinitionMutation, useGetSchemaDefinitionsQuery } from "../../gateways/cms/schemaApi";
import MainLayout from "../../layouts/MainLayout";

import { styled } from "@mui/system";
import { makeStyles } from "@material-ui/styles";
import ButtonMUI from "@material-ui/core/Button";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { PaginationLinks } from "../../components/PaginationLinks";
import Color from "../../styles/colors.js";
import VarConst from "../../styles/variables.js";
import iconEllipsis from "../../assets/icons/icon_ellipsis.svg";
import iconCheckboxOff from "../../assets/icons/icon_checkbox_off.svg";
import iconCheckboxOn from "../../assets/icons/icon_checkbox_on.svg";
import { EditMenuMinimum } from "../../components/materials/EditMenuMinimum";
import { SortMenu } from "../../components/SortMenu";

// style
const varCss = {
    articleListStaticItemsW: "260px + 24px + 3em + 8em + 8em + 3em + 24px",
};
const PageTitle = styled("section")`
    display: flex;
    justify-content: space-between;
    padding: 24px;
    // margin-bottom: 16px;

    h1 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;
    }
`;
const PageMain = styled("section")`
    display: flex;
    flex-direction: column;
    padding: 0 24px 0;
    overflow: hidden;

    & > main {
        overflow: auto;
    }
`;
const BarList = styled("section")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    font-size: 14px;

    [class$="MuiStack-root"]{
        align-items: center;

        & > :not(style) + :not(style) {
            margin-left: 16px;
        }
    }
`;
const WrapperSticky = styled("section")`
    position: relative;
    overflow: auto;
    margin-bottom: 24px;
    width: 100%;
`;
const TableCommon = styled("table")`
    table-layout: auto;
    border-spacing: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    border-bottom: ${VarConst.border1};

    font-size: 13px;

    thead {
        position: sticky;
        top: 0;
        z-index: 10;
    }

    tr > th,
    tr > td {
        border-top: ${VarConst.border1};
        border-right: ${VarConst.border1};
        line-height: 1.1;

        &.checkbox {
            width: 3em; // 固定
            min-width: 3em; // 固定
            max-width: 3em; // 固定
            position: relative;
            border-left: ${VarConst.border1};

            input[type="checkbox"] {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;
                width: 24px;
                height: 24px;
                margin: 0;
                opacity: 0;
                cursor: pointer;
                transform: translate( -50%, -50%);
                
                & + span::before {
                    content: "";
                    display: block;
                    width: 24px;
                    height: 24px;
                    pointer-events: none;
                    background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
                }
                &:checked + span::before {
                    background-image: url(${iconCheckboxOn});
                }
            }
        }
        &.id {
            width: 5em; // 固定
            min-width: 5em; // 固定
            max-width: 5em; // 固定
        }
        &.draft_test {
            width: 6em; // 固定
            min-width: 6em; // 固定
            max-width: 6em; // 固定
            text-align: center;
            .MuiChip-root {
                background-color: dimgray;
                color: white;
                font-size: 10px;

            }
        }
        &.draft_public {
            width: 6em; // 固定
            min-width: 6em; // 固定
            max-width: 6em; // 固定
            text-align: center;
            .MuiChip-root {
                background-color: navy;
                color: white;
                font-size: 11px;

            }
        }
        &.title {
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.25);
        }
        &.organization {
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.45);
        }
        &.usergroup {
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.3);
        }
        &.updated {
            width: 8em;
            min-width: 8em;
            max-width: 8em;
        }
        &.function {
            width: 3em; // 固定
            min-width: 3em; // 固定
            max-width: 3em; // 固定
        }
    }
    tr > th {
        padding: 4px 0 4px 8px;
        background-color: ${Color.bgHead};
        text-align: left;
        font-weight: normal;
        border-bottom: ${VarConst.border1};

        &.function {
            text-align: center;
            padding: 0;
        }
    }
    tr > td {
        height: 56px;
        padding: 4px 8px;

        ul {
            /* display: flex; */
            li {
                display: inline;
            }
            li + li::before {
                content: ", ";
            }
        }

        &.function {
            padding-top: 12px;
            padding-bottom: 12px;

            button {
                // IconButtonに hrefがないので Buttonを使用
                height: 24px;
                width: 24px;
                min-width: 24px;
                border: ${VarConst.border1};
                border-width: 2px;
                border-radius: 2px;
                background-image: url(${iconEllipsis});
                background-repeat: no-repeat;
                background-position: center center;
                background-color: #fff;
            }
        }
    }

    // 状態表示
    tr {
        .status div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 2em;
            margin: 0 auto;
            color: #fff;
            line-height: 1;

            b {
                writing-mode: vertical-lr;
                font-weight: normal;
            }
        }
    }
`;

const FunctionHeader = styled("section")`
    display: flex;
    justify-content: space-between;
    align-items: center;

    b {
        flex: 1;
        font-weight: normal;
    }
`;

const Button = styled(ButtonMUI)`
    &,
    &.MuiButton-outlined,
    &.MuiButton-contained {
        height: 32px;
        border-radius: 2px;
    }
    &.MuiButton-outlined {
        background-color: #fff;
        border: 2px solid #c9cbd3;
    }
    &.MuiButton-contained {
        width: 116px;
        margin-left: 16px;
        color: #f4f4f4;
        background-color: #3a3e5d;
        border-color: #3a3e5d;
    }
`;
const ButtonHD = styled(ButtonMUI)`
    &,
    &.MuiButton-outlined,
    &.MuiButton-contained {
        height: 32px;
        width: 180px;
        border-radius: 2px;
    }
`;

export function SchemaDefinitionListPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const definitions = useGetSchemaDefinitionsQuery({
        sort_key: searchParams.get("sortKey") || undefined,
        sort_direction: searchParams.get("sortDirection") || undefined,
    });
    const [definitionBulkDelete] = useBulkDeleteSchemaDefinitionMutation();
    const [selectedIds, setSelectedIds] = useState([] as number[]);

    const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelectedIds([...selectedIds, Number(e.target.value)]);
        } else {
            setSelectedIds(selectedIds.filter((id) => id !== Number(e.target.value)));
        }
    };

    const handleDelete = (id: number) => () => {
        definitionBulkDelete([id]);
        definitions.refetch();
    };

    const handleBulkDelete = async () => {
        await definitionBulkDelete(selectedIds);
        setSelectedIds([]);
        definitions.refetch();
    };

    const handleSortBy = (key: string, direction: string) => () => {
        searchParams.set("sortKey", key);
        searchParams.set("sortDirection", direction);
        setSearchParams(searchParams);
    };

    const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelectedIds(definitions.data?.data.map((d) => d.id) || []);
        } else {
            setSelectedIds([]);
        }
    };

    
    const useStyles = makeStyles({
        createButton: {
            height: '32px',
            borderRadius: '1px',
            width: '116px',
            marginLeft: '10px',
            color: '#f4f4f4',
            backgroundColor: '#3a3e5d',
            borderColor: '#3a3e5d',
            letterSpacing: '0.05em',
        },
    });
    const buttonClasses = useStyles();

    const getDraft = (draft: boolean) => {
        if(draft) return "テスト用";
        else return "本番用";
    }

    const getDraftClass = (draft: boolean) => {
        if(draft) return "draft_test";
        else return "draft_public";
    }

    // console.log(definitions);

    return (
        <MainLayout title="コンテンツスキーマ管理">
            <PageTitle>
                <h1>コンテンツスキーマ管理</h1>
                <Stack spacing={1} direction="row">
                    <ButtonHD className={buttonClasses.createButton} variant="contained" disableElevation href={`/schema-definitions/minimum/create`}>
                        回数保証スキーマ作成
                    </ButtonHD>
                    <ButtonHD className={buttonClasses.createButton} variant="contained" disableElevation href={`/schema-definitions/create`}>
                        通常スキーマ作成
                    </ButtonHD>
                </Stack>
            </PageTitle>

            <PageMain>
                <BarList>
                    <Stack spacing={1} direction="row">
                        <Button type="button" onClick={handleBulkDelete}>
                            {/* まとめて */}削除
                        </Button>
                    </Stack>

                    <Stack spacing={1} direction="row">
                        <span>選択数: {selectedIds.length}</span> 
                        <span>登録件数: {definitions.data?.total}</span>
                        <span>
                            {definitions.data && <PaginationLinks meta={definitions.data} />}
                        </span>
                    </Stack>
                </BarList>

                <WrapperSticky>
                    <TableCommon>
                        <thead>
                            <tr>
                                <th className="checkbox">
                                    <input
                                        type="checkbox"
                                        checked={selectedIds.length > 0}
                                        onChange={handleSelectAll}
                                    /><span></span>
                                </th>
                                <th className="id">ID</th>
                                <th className="draft">用途</th>
                                <th className="title">
                                    <FunctionHeader>
                                        <b>コンテンツタイプ</b>
                                        <SortMenu
                                            menuAsc={
                                                <button type="button" onClick={handleSortBy("name", "asc")}>
                                                    昇順 ( A→Z )
                                                </button>
                                            }
                                            menuDesc={
                                                <button type="button" onClick={handleSortBy("name", "desc")}>
                                                    降順 ( Z→A )
                                                </button>
                                            }
                                        />
                                    </FunctionHeader>
                                </th>
                                <th className="organization">
                                    <FunctionHeader>
                                        <b>利用可能テナント</b>
                                        <SortMenu
                                            menuAsc={
                                                <button type="button" onClick={handleSortBy("organizationId", "asc")}>
                                                    昇順 ( A→Z )
                                                </button>
                                            }
                                            menuDesc={
                                                <button type="button" onClick={handleSortBy("organizationId", "desc")}>
                                                    降順 ( Z→A )
                                                </button>
                                            }
                                        />
                                    </FunctionHeader>
                                </th>
                                <th className="usergroup">
                                    <FunctionHeader>
                                        <b>利用可能ユーザーグループ</b>
                                        <SortMenu
                                            menuAsc={
                                                <button type="button" onClick={handleSortBy("authority", "asc")}>
                                                    昇順 ( A→Z )
                                                </button>
                                            }
                                            menuDesc={
                                                <button type="button" onClick={handleSortBy("authority", "desc")}>
                                                    降順 ( Z→A )
                                                </button>
                                            }
                                        />
                                    </FunctionHeader>
                                </th>
                                <th className="updated">
                                    <FunctionHeader>
                                        <b>更新日時</b>
                                        <SortMenu
                                            menuAsc={
                                                <button type="button" onClick={handleSortBy("updatedAt", "asc")}>
                                                    昇順 ( A→Z )
                                                </button>
                                            }
                                            menuDesc={
                                                <button type="button" onClick={handleSortBy("updatedAt", "desc")}>
                                                    降順 ( Z→A )
                                                </button>
                                            }
                                        />
                                    </FunctionHeader>
                                </th>
                                <th className="function">操作</th>
                            </tr>
                        </thead>

                        <tbody>
                            {definitions.data?.data?.map((definition) => (
                                <tr key={definition.id}>
                                    <td className="checkbox">
                                        <input
                                            type="checkbox"
                                            value={definition.id}
                                            checked={selectedIds.includes(definition.id)}
                                            onChange={handleSelect}
                                        /><span></span>
                                    </td>
                                    <td className="id" style={{ backgroundColor: definition.backgroundColor, color: definition.labelColor}}>
                                        {definition.id}
                                    </td>
                                    <td className={getDraftClass(definition.draft)} style={{ backgroundColor: definition.backgroundColor, color: definition.labelColor}}>
                                        <Chip
                                            label={getDraft(definition.draft)}
                                            size="small"
                                        />
                                    </td>
                                    <td className="title" style={{ backgroundColor: definition.backgroundColor, color: definition.labelColor}}>
                                        {definition.title}
                                    </td>
                                    <td className="organization">
                                        {definition.organization.map((orgItem) => orgItem.name).join("、")}
                                    </td>
                                    <td className="usergroup">
                                        {definition.usergroup.map((uGroup) => uGroup.name).join("、")}
                                    </td>
                                    <td className="updated">{definition.updatedAt}</td>
                                    <td className="function">
                                        <EditMenuMinimum
                                            menuEdit={
                                                <Link to={`/schema-definitions/${definition.id}/edit`}>編集</Link>
                                            }
                                            menuDelete={
                                                <button type="button" onClick={handleDelete(definition.id)}>
                                                    削除
                                                </button>
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </TableCommon>
                </WrapperSticky>
            </PageMain>
        </MainLayout>
    );
}
